import React, {useEffect} from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'aos/dist/aos.css'
import "../scss/style.scss";
import type {AppProps} from 'next/app'
import AppWrapper from "../AppWrapper";
import ToastProvider from "../atoms/Toast/ToastProvider";
import {GlobalProvider} from "../providers/GlobalProvider";
import {IProps} from "../types/homePageTypes";
import AOS from 'aos'
import ErrorBoundary from "../ErrorBoundary";
import {AgeVerificationPopupLayout} from "../providers/AgeVerificationPopupLayout";
import { useAnalytics } from "../hooks/useAnalytics";

export default function App({Component, pageProps}: AppProps) {
    const typedPageProps = pageProps as unknown as IProps
    const { isMobile, GTAG_ID, ageVerifications } = typedPageProps

    useEffect(() => {
        AOS.init({duration: 1000})
    }, [])

    useAnalytics(GTAG_ID, isMobile)

    return (
        <ErrorBoundary>
            <AppWrapper>
                <GlobalProvider value={typedPageProps}>
                    <ToastProvider>
                        <AgeVerificationPopupLayout ageVerifications={ageVerifications}>
                            <Component {...pageProps} />
                        </AgeVerificationPopupLayout>
                    </ToastProvider>
                </GlobalProvider>
            </AppWrapper>
        </ErrorBoundary>
    );
}
