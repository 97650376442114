const getEmbedUrl = (src = '', autoPlay = false) => {
    const youtubeWatchPattern = /https:\/\/www\.youtube\.com\/watch\?v=([\w-]+)/;
    const match = src.match(youtubeWatchPattern);

    if (match) {
        const queryParams = new URLSearchParams({
            autoplay: autoPlay ? '1' : '0',
            mute: autoPlay ? '1' : '0',
            controls: '0'
        }).toString();

        return `https://www.youtube.com/embed/${match[1]}?${queryParams}`
    }
    return null;
}

module.exports = {
    getEmbedUrl
}