const parseClone = (obj) => {
    return JSON.parse(JSON.stringify(obj));
}

const buildTree = (array, parentId, selectedLocale, label, forMenu) => {
    let children = []
    for (let i = 0, length = array.length; i < length; i++) {
        const href =
            array[i].url_key
                ? `${array[i].url_key}`
                : array[i].custom_url
                    ? `/${array[i].custom_url}`
                    : array[i]?.slug
                        ? `/${selectedLocale}/${label}/${array[i]?.slug}`
                        : array[i]?.page_id
                            ? `/page/${array[i]?.page_id}`
                            : '';
        if (array[i].parent_id === parentId && (forMenu === true ? true : array[i].id === 1)) {
            let child = array[i]
            child.children = buildTree(array, child.id, selectedLocale, label, true)
            child.frontLink = href;
            child.url_key = array[i].url_key ? array[i].url_key : `/page/${array[i].slug}`;
            child.target = array[i].new_tab ? {target: "_blank"} : {}
            children.push(child)
        }
    }
    return children
}

const makeImageClone = (path) => {
    return {
        path: path,
        url: `/storage/${path}`,
        large_image_url: `/cache/large/${path}`,
        medium_image_url: `/cache/medium/${path}`,
        small_image_url: `/cache/small/${path}`,
        original_image_url: `/cache/original/${path}`,
    };
}

const imageAddFields = (product, options = {}) => {
    return product.map(item => {
        let base_image;
        if (item.images?.length > 0) {
            item.images.map((x, ind) => {
                base_image = makeImageClone(x);
                item.images[ind] = base_image;
            });
        }
        if (Object.keys(options).length > 0) {
            return {...item, ...options}
        } else {
            return item;
        }
    })
}

const minQuantityFieldAdd = (product, minQuantityArr) => {

    return product.map(elem => {

        const minQuantityValue = minQuantityArr.find(item => item.product_id === elem.id)
        const {text_value: min_qty} = minQuantityValue || {}
        if (min_qty && +min_qty > 1) {
            /*TODO this part always took the first element, so i changed it to right way*/
            // return {
            //     ...elem,
            //     flats: [{
            //         ...elem.flats[0],
            //         min_qty: +min_qty,
            //     }]
            // }
            return {
                ...elem,
                flats: elem.flats.map(obj => ({...obj, min_qty: +min_qty}))
            }
        }
        return elem
    })
}

const arrayConvertToObject = (response) => {
    return response.reduce((prev, next) => {
        const keys = Object.keys(next);
        if (keys.length > 1) {
            const array = keys.reduce((a, n) => {
                return {
                    ...a, [n]: next[n],
                };
            }, {});
            return {...prev, ...array};
        } else {
            return {...prev, [keys]: next[keys]};
        }
    }, {});
}

const convertDateToSeconds = (date = null, currentDate = false) => {
    let dateObj = new Date(date)
    let currDateObj = new Date()

    return currentDate ? currDateObj.getTime() / 1000 : dateObj.getTime() / 1000;
}

const defaultFilter = ({key, options}) => {

    let dynamicSearchKey;
    switch (typeof options[key]) {
        case "boolean":
            dynamicSearchKey = "boolean_value";
            break;
        default:
            let isNum = /^\d+$/.test(options[key]);
            switch (isNum) {
                case true:
                    dynamicSearchKey = "integer_value";
                    break;
                default:
                    dynamicSearchKey = "text_value";
                    break;
            }
    }

    if (options.isSearch) {
        delete options.isSearch;
        let value;
        if (options[key].includes(",")) {
            dynamicSearchKey = "integer_value";
            value = options[key].split(",");
        } else {
            value = options[key];
        }
        return {[dynamicSearchKey]: value};
    } else {
        return {}
    }
}

const findMinimalOrMaximalPrice = (obj, order) => {
    const START_DATE = "1970-01-01";

    if (obj) {
        const {price, min_price, max_price, special_price} = obj
        let newDate = new Date();
        let pricesArr
        let date_from = obj.special_price_from
        let date_to = obj.special_price_to
        const date_now = newDate;
        if (special_price && date_now >= date_from && date_now <= date_to) {
            pricesArr = [price, min_price, max_price, special_price]
        } else if (special_price && date_from === START_DATE && date_to === START_DATE) {
            pricesArr = [price, min_price, max_price, special_price]
        } else {
            pricesArr = [price, min_price, max_price]
        }

        if (price || min_price) {
            if (order === 1) {
                return pricesArr?.filter(el => el !== null).sort((a, b) => b - a)[0]
            } else {
                return pricesArr?.filter(el => el !== null).sort((a, b) => a - b)[0]
            }
        }
    }

}

const findFlatsExactLocale = (res, locale) => {
    return res.map(item => {
        const parsedObj = parseClone(item)
        return {
            ...parsedObj,
            flats: parsedObj.flats.filter(sub => sub.locale === locale)
        }
    })
}

const buildNeededData = (res) => {
    const getPrice = (flats, direction) => findMinimalOrMaximalPrice(flats[0], direction);
    const setMinPrice = res.map((item) => getPrice(item.flats, -1)).sort((a, b) => a - b)[0];
    const setMaxPrice = res.length !== 1 ? res.map((item) =>  getPrice(item.flats, 1)).sort((a, b) => b - a)[0] : setMinPrice
    console.log(setMinPrice, "buildNeededData ---- setMinPrice")
    console.log(setMaxPrice, "buildNeededData ---- setMaxPrice")
    let confMinPrice = setMinPrice;
    let confMaxPrice = setMaxPrice;
    res.forEach((item) => {
        if (item.variants.length > 0) {
            confMinPrice = item.variants.map((el) => findMinimalOrMaximalPrice(el.flats[0], -1)).sort((a, b) => a - b)[0]
            confMaxPrice = item.variants.map((el) => findMinimalOrMaximalPrice(el.flats[0], 1)).sort((a, b) => b - a)[0]
        }
        item.min_price = confMinPrice;
    })
    const minPrice = Math.min(setMinPrice, confMinPrice);
    const maxPrice = Math.max(setMaxPrice, confMaxPrice);
    console.log(minPrice, "buildNeededData, ---- minPrice")
    console.log(maxPrice, "buildNeededData ---- maxPrice")

    return {
        data: res,
        max_price: setMaxPrice || 0,
        dispatches: {
            setInitialMinPrice: minPrice || 0,
            setInitialMaxPrice: maxPrice || 0,
        },
    }
}

const handleContentIsEmpty = (content = "") => {
    return !!content.replace(/<\/?[^>]+(>|$)/g, "").replaceAll("&nbsp;", "")
}

module.exports = {
    parseClone,
    buildTree,
    makeImageClone,
    arrayConvertToObject,
    convertDateToSeconds,
    defaultFilter,
    findMinimalOrMaximalPrice,
    findFlatsExactLocale,
    buildNeededData,
    imageAddFields,
    minQuantityFieldAdd,
    handleContentIsEmpty,
}