import { useEffect } from "react";
import { useRouter } from "next/router";
import { handleRouteChange, loadGtag } from "../helpers/appendGAScripts";

export const useAnalytics = (GTAG_ID: string, isMobile: boolean) => {
    const router = useRouter()
    useEffect(() => {
        const handleRouteChangeComplete = (url: string) => handleRouteChange(url, GTAG_ID);

        if (isMobile) {
            const handleScroll = () => setTimeout(() => loadGtag(GTAG_ID), 500);
            window.addEventListener("scroll", handleScroll, { once: true });
            return () => window.removeEventListener("scroll", handleScroll);
        } else loadGtag(GTAG_ID);

        router.events.on("routeChangeComplete", handleRouteChangeComplete);
        return () => router.events.off("routeChangeComplete", handleRouteChangeComplete);

    }, [router.events, isMobile, GTAG_ID]);
}